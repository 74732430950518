<template>
  <div class="pa-5">
    <v-select label="版本" :items="types" v-model="type" @change="fetchData"></v-select>
    <v-select label="章节" :items="chapters" item-text="title" item-value="id" v-model="chapterId" @change="fetchData"></v-select>
    <div class="article-content" v-html="article.content"></div>
  </div>
</template>

<script>
import service from '@/utils/request';

export default {
  name: "Read",
  data() {
    return {
      chapters: [],
      type: '',
      chapterId: 0,
      article: {}
    }
  },
  computed: {
    types() {
      if(this.$store.getters.isLoggedIn) {
        return '嘉靖壬午本,叶逢春本,黄正甫本,通行本,章节史料,章节注释'.split(',');
      } else {
        return '嘉靖壬午本,黄正甫本,通行本'.split(',');
      }
    }
  },
  created() {
    this.type = this.$route.params.type;
    this.chapterId = this.$route.params.chapterId * 1;
    this.fetchData();
  },
  methods: {
    async fetchTypes() {
      const response = await service.get('/wikiapi/read/types');
      this.types = response.data;
    },
    async fetchChapters() {
      const response = await service.get('/wikiapi/read/list', { params: { type: this.type } });
      this.chapters = response.data;
    },
    async fetchArticle() {
      const response = await service.get(`/wikiapi/read/article/${this.chapterId}`, { params: { type: this.type } });
      this.article = response.data;
    },
    async fetchData() {
      await this.fetchChapters();
      await this.fetchArticle();
      this.$router.push({ name: '阅读', params: { type: this.type, chapterId: this.chapterId } });
    }
  }
};
</script>

<style>
.s3{
  font-weight: bolder;
}
.q{
    color: #008080;
}
.h{
    color: #FF0000;
}
.z{
    color: #FF00FF;
}
.l{
    color: #000080;
}
.article-content img {
  width: 100%;
  height: auto;
  max-width: 600px;
  display: block;
  margin: 0 auto;
  border-bottom: 20px solid #eee !important;
}
.article-content img:last-child {
  border-bottom: none;
}
</style>